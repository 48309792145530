import styled from 'styled-components';

export const FooterWrapper = styled.div`
    width: 100vw;
    background: gray;
    text-align: center;
    color: white;
    padding: 40px 0;
`
export const FooterText = styled.p`
    
`